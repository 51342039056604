/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IconRoute from "../../icons/IconRoute";
import { AddType, DetailsBookingType } from "../../utils/globalTypes";
import { IconShip } from "../../icons/IconShip";
import { AddiatinalServisexEnum, StatusEnum } from "./types";
import { CustomModal } from "../CustomModal";
import {
  StyledAdditionalServiceItemColumn,
  StyledAdditionalServiceItemColumns,
  StyledAdditionalServiceList,
  StyledAdditionalServiceTypeName,
  StyledBookingInfoFormWrapper,
  StyledBookingInfoItem,
  StyledBookingInfoItemColumnsWrapper,
  StyledBookingInfoItemTitle,
  StyledBookingInfoWrapper,
  StyledBookingItemsList,
  StyledBorderRoute,
  StyledContentColumnCurrentBooking,
  StyledDatesService,
  StyledDateStartAdventure,
  StyledDisclaimer,
  StyledDisclaimerText,
  StyledDisclaimerTitle,
  StyledFormCurrnetBookingItem,
  StyledFormCurrnetBookingItemWrapper,
  StyledIconRouteWrapper,
  StyledIconWrapper,
  StyledLinkRoute,
  StyledModalBookingContactColumn,
  StyledModalBookingContactColumnData,
  StyledModalBookingContactColumnsWrapper,
  StyledModalBookingContactColumnTitle,
  StyledModalBookingContactContent,
  StyledNameCategoryService,
  StyledNameColumnCurrentBooking,
  StyledNameSevice,
  StyledRouteDate,
  StyledRouteInformation,
  StyledRouteInformationItem,
  StyledRouteName,
  StyledSectionDateStartAdventure,
} from "./CurrentBooking.styles";

type CurrentBookingProps = {
  booking: DetailsBookingType | undefined;
  isShowDisclaimerDisabledEditForms?: boolean;
};

export const CurrentBooking = ({
  booking,
  isShowDisclaimerDisabledEditForms,
}: CurrentBookingProps) => {
  const { t } = useTranslation();

  const [openModalBookingContact, setOpenModalBookingContact] = useState(false);

  const lastIndexRoute = Number(booking?.cruise?.route?.length) - 1;

  const startRoute = booking?.cruise?.route[0];
  const endRoute = booking?.cruise?.route[lastIndexRoute];

  // Так бекенд не отдает имена категорий
  // value должно совпадать с параметром period_of_service_provision
  // значение value для каждой категории задал Александр Моноскин
  const aditionalServicesCategory: any = {
    1: {
      name: AddiatinalServisexEnum.preCruise,
      value: 1,
    },
    2: {
      name: AddiatinalServisexEnum.onBoard,
      value: 2,
    },
    3: {
      name: AddiatinalServisexEnum.postCruise,
      value: 3,
    },
    4: {
      name: AddiatinalServisexEnum.excursions,
      value: 4,
    },
  };

  const arrAditionalServicesCategory = Object.values(aditionalServicesCategory);

  // Группировка массива "Additional services"
  const groupByKeyServices = (array: any, key: any) => {
    return array?.reduce((hash: any, obj: any) => {
      return Object.assign(hash, {
        [aditionalServicesCategory?.[obj[key]]?.name]: (
          hash[aditionalServicesCategory?.[obj[key]]?.name] || []
        ).concat(obj),
      });
    }, []);
  };

  // period_of_service_provision -> это ключ по которому происходит группировка
  const groupAdditionalServices = booking?.adds
    ? groupByKeyServices(booking?.adds, "period_of_service_provision")
    : {};

  // Массив дат "Additional services"
  const addDatesStart = booking?.adds?.map((item) => item.start_date);

  // Самая ранняя дата окозания услуг
  const firstDateAddServices =
    !!addDatesStart?.length &&
    addDatesStart?.reduce(function (pre, cur) {
      return Date.parse(pre) > Date.parse(cur) ? cur : pre;
    });

  const getStatusCurrentBooking = () => {
    if (booking?.status_id === 1) {
      return StatusEnum.booked;
    }
    if (booking?.status_id === 4) {
      return StatusEnum.canceled;
    }
    if (booking?.status_id === 8) {
      return StatusEnum.inCruise;
    }
    if (booking?.status_id === 11) {
      return StatusEnum.success;
    }
    if (booking?.status_id === 12) {
      return StatusEnum.hold;
    }
    return "-";
  };

  return (
    <StyledBookingInfoWrapper>
      <StyledBookingInfoItem>
        {isShowDisclaimerDisabledEditForms && (
          <StyledDisclaimer>
            <StyledDisclaimerTitle className="alert">
              {t("changesAreNotAllowed")}
            </StyledDisclaimerTitle>
            <StyledDisclaimerText className="alert">
              {t("changesAreNotAllowedMessage")}
            </StyledDisclaimerText>
          </StyledDisclaimer>
        )}
        
        <StyledDisclaimer>
          <StyledDisclaimerTitle>
            {t("importantInformation")}
          </StyledDisclaimerTitle>
          <StyledDisclaimerText>{t("yourFinalDocuments")}</StyledDisclaimerText>
        </StyledDisclaimer>

        <StyledBookingInfoItemTitle>
          <span>{t("currentBooking")}</span>
          <span
            className="openModalBookingContact"
            onClick={() => setOpenModalBookingContact(true)}
          >
            {t("YourBookingContact")}
          </span>
        </StyledBookingInfoItemTitle>

        <StyledBookingInfoItemColumnsWrapper>
          <StyledBookingInfoFormWrapper>
            <StyledBookingItemsList>
              <StyledFormCurrnetBookingItemWrapper>
                <StyledFormCurrnetBookingItem>
                  <StyledNameColumnCurrentBooking>
                    {t("bookingCode")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.number}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>

                <StyledFormCurrnetBookingItem>
                  <StyledNameColumnCurrentBooking>
                    {t("ship")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    <StyledIconWrapper>
                      <IconShip />
                      {booking?.cruise?.motorship?.name}
                    </StyledIconWrapper>
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>

                <StyledFormCurrnetBookingItem>
                  <StyledNameColumnCurrentBooking>
                    {t("deck")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.rooms[0].room?.deck?.name}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>
              </StyledFormCurrnetBookingItemWrapper>

              <StyledFormCurrnetBookingItemWrapper>
                <StyledFormCurrnetBookingItem className="lastColumnInMobile">
                  <StyledNameColumnCurrentBooking>
                    {t("status")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking className="status">
                    {getStatusCurrentBooking()}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>

                <StyledFormCurrnetBookingItem className="lastColumnInMobile">
                  <StyledNameColumnCurrentBooking>
                    {t("cruiseName")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.cruise?.name}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>

                <StyledFormCurrnetBookingItem className="lastColumnInMobile">
                  <StyledNameColumnCurrentBooking>
                    {t("category")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.rooms[0]?.room?.room_class?.meta?.name}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>
              </StyledFormCurrnetBookingItemWrapper>

              <StyledFormCurrnetBookingItemWrapper className="lastColumnInMobile">
                <StyledFormCurrnetBookingItem>
                  <StyledNameColumnCurrentBooking>
                    {t("cruiseCode")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.cruise?.code}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>

                <StyledFormCurrnetBookingItem className="lastColumnInMobile">
                  <StyledNameColumnCurrentBooking>
                    {t("stateRoomNumber")}
                  </StyledNameColumnCurrentBooking>

                  <StyledContentColumnCurrentBooking>
                    {booking?.rooms[0].room?.number}
                  </StyledContentColumnCurrentBooking>
                </StyledFormCurrnetBookingItem>
              </StyledFormCurrnetBookingItemWrapper>
            </StyledBookingItemsList>
          </StyledBookingInfoFormWrapper>

          {/* ROUTE */}
          <StyledRouteInformation>
            <StyledBorderRoute>
              <StyledIconRouteWrapper className="startRoute">
                <IconRoute />
              </StyledIconRouteWrapper>

              <StyledRouteInformationItem>
                <StyledRouteName>{startRoute?.city?.city_name}</StyledRouteName>
                <StyledRouteDate>
                  <p>
                    {startRoute?.date
                      ? moment(startRoute?.date, "DD.MM.YYYY").format(
                          "dddd, DD MMM YYYY"
                        )
                      : "-"}
                  </p>
                  <p>
                    {t("PierToEmbark")}:{startRoute?.city?.port_name}
                  </p>
                </StyledRouteDate>
              </StyledRouteInformationItem>

              <StyledLinkRoute>Route</StyledLinkRoute>

              <StyledRouteInformationItem className="endRoute">
                <StyledRouteName className="portNameEnd">
                  {endRoute?.city?.city_name}
                </StyledRouteName>

                <StyledRouteInformationItem className="mobile">
                  <StyledRouteDate>
                    <p>
                      {endRoute?.date
                        ? moment(endRoute?.date, "DD.MM.YYYY").format(
                            "dddd, DD MMM YYYY"
                          )
                        : "-"}
                    </p>
                    <p>
                      {t("PierDebark")}:{endRoute?.city?.port_name}
                    </p>
                  </StyledRouteDate>
                </StyledRouteInformationItem>
              </StyledRouteInformationItem>

              <StyledIconRouteWrapper className="endRoute">
                <IconRoute />
              </StyledIconRouteWrapper>
            </StyledBorderRoute>

            <StyledRouteInformationItem className="desktop">
              <StyledRouteDate>
                <p>
                  {endRoute?.date
                    ? moment(endRoute?.date, "DD.MM.YYYY").format(
                        "dddd, DD MMM YYYY"
                      )
                    : "-"}
                </p>
                <p>
                  {t("PierDebark")}:{endRoute?.city?.port_name}
                </p>
              </StyledRouteDate>
            </StyledRouteInformationItem>
          </StyledRouteInformation>
        </StyledBookingInfoItemColumnsWrapper>
      </StyledBookingInfoItem>

      <StyledBookingInfoItem>
        <StyledBookingInfoItemTitle>
          {t("additionalServices")}
        </StyledBookingInfoItemTitle>

        <StyledSectionDateStartAdventure>
          {t("YourStartAdventure")}
          <StyledDateStartAdventure>
            {firstDateAddServices
              ? moment(firstDateAddServices).format("DD MMM YYYY")
              : "-"}
          </StyledDateStartAdventure>
        </StyledSectionDateStartAdventure>

        <StyledAdditionalServiceList>
          {arrAditionalServicesCategory?.map((service: any) => {
            return (
              <StyledAdditionalServiceList>
                <StyledAdditionalServiceItemColumn>
                  {/* Название категории  */}
                  <StyledAdditionalServiceTypeName>
                    {service?.name}
                  </StyledAdditionalServiceTypeName>

                  {/* Сервисы входящие в категорию */}
                  {groupAdditionalServices?.[service?.name]?.map(
                    (item: AddType) => {
                      return (
                        <StyledAdditionalServiceItemColumns key={item?.id}>
                          <StyledAdditionalServiceItemColumn className="name">
                            <StyledDatesService>
                              <span>
                                {item?.start_date
                                  ? moment(item?.start_date).format(
                                      "DD MMM YYYY"
                                    )
                                  : "-"}
                              </span>
                              <span>-</span>
                              <span>
                                {item?.end_date
                                  ? moment(item?.end_date).format("DD MMM YYYY")
                                  : ""}
                              </span>
                            </StyledDatesService>

                            <StyledNameSevice>
                              <StyledNameCategoryService>
                                {item?.type?.name ?? "-"}
                              </StyledNameCategoryService>
                              {item?.name}
                            </StyledNameSevice>
                          </StyledAdditionalServiceItemColumn>
                        </StyledAdditionalServiceItemColumns>
                      );
                    }
                  )}
                </StyledAdditionalServiceItemColumn>
              </StyledAdditionalServiceList>
            );
          })}
        </StyledAdditionalServiceList>
      </StyledBookingInfoItem>

      {/* Модальное окно "Your booking contact" */}
      <CustomModal
        open={openModalBookingContact}
        setOpen={(value) => setOpenModalBookingContact(value)}
        title={t("YourBookingContact")}
      >
        <StyledModalBookingContactContent>
          <StyledModalBookingContactColumnsWrapper>
            <StyledModalBookingContactColumn>
              <StyledModalBookingContactColumnTitle>
                {t("agency")}
              </StyledModalBookingContactColumnTitle>
              <StyledModalBookingContactColumnData>
                {booking?.owner?.agency ?? "-"}
              </StyledModalBookingContactColumnData>
            </StyledModalBookingContactColumn>

            <StyledModalBookingContactColumn>
              <StyledModalBookingContactColumnTitle>
                {t("bookingAgent")}
              </StyledModalBookingContactColumnTitle>
              <StyledModalBookingContactColumnData>
                {booking?.owner?.first_name ?? "-"}
              </StyledModalBookingContactColumnData>
            </StyledModalBookingContactColumn>

            <StyledModalBookingContactColumn>
              <StyledModalBookingContactColumnTitle>
                {t("phone")}
              </StyledModalBookingContactColumnTitle>
              <StyledModalBookingContactColumnData>
                {booking?.owner?.phone?.length ? booking?.owner?.phone : "-"}
              </StyledModalBookingContactColumnData>
            </StyledModalBookingContactColumn>

            <StyledModalBookingContactColumn>
              <StyledModalBookingContactColumnTitle>
                {t("email")}
              </StyledModalBookingContactColumnTitle>
              <StyledModalBookingContactColumnData>
                {booking?.owner?.email ?? "-"}
              </StyledModalBookingContactColumnData>
            </StyledModalBookingContactColumn>
          </StyledModalBookingContactColumnsWrapper>
        </StyledModalBookingContactContent>
      </CustomModal>
    </StyledBookingInfoWrapper>
  );
};
