import { useTranslation } from "react-i18next";
import IconPdfDoc from "../../icons/IconPdfDoc";
import {
  StyledBookingInfoPersonalInfoContentSubItem,
  StyledBookingInfoPersonalInfoContentSubItemTitle,
  StyledDocumentIconWrapper,
  StyledDocumentName,
} from "./DocumentsBooking.styles";

export const DocumentsBooking = () => {
  const { t } = useTranslation();
  return (
    <StyledBookingInfoPersonalInfoContentSubItem>
      <StyledBookingInfoPersonalInfoContentSubItemTitle>
        {t("documents")}
      </StyledBookingInfoPersonalInfoContentSubItemTitle>
      <StyledDocumentIconWrapper>
        <IconPdfDoc />
        {/* <StyledDocumentName>{t("boardingPass")}</StyledDocumentName> */}
        <StyledDocumentName>Your travel documents will be available here</StyledDocumentName>
      </StyledDocumentIconWrapper>
    </StyledBookingInfoPersonalInfoContentSubItem>
  );
};
