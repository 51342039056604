/* eslint-disable */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { StyledWrapper } from "./CustomCheckbox.styles";

type CustomCheckboxProps = {
  label: string;
  value: any;
  setValue: (value: boolean) => void;
  disabled?: boolean
};

export const CustomCheckbox = ({
  label,
  value,
  setValue,
  disabled
}: CustomCheckboxProps) => {
  return (
    <FormGroup>
      <StyledWrapper>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(event) => setValue(event.target.checked)}
              sx={{
                color: "#004155",
                "&.Mui-checked": {
                  color: "#004155",
                },
              }}
            />
          }
          label={label}
          disabled={disabled}
        />
      </StyledWrapper>
    </FormGroup>
  );
};
