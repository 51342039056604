import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledAccordionBookingWrapper = styled.div`
  margin-top: 12px;
`

export const StyledAccordionBookingTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegularMedium};
`