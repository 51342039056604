import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CustomSelectProps } from "./types";
import { StyledCustomSelectWrapper } from "./CustomSelect.styles";

export const CustomSelect = ({ label, children, defaultValue, onChange, variant, isScrollEnd }: CustomSelectProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue ?? "");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event.target.value);
  };

  const loadMoreItems = (e: any) => {
    if(Math.round(e.target.scrollTop) + e.target.clientHeight === e.target.scrollHeight && isScrollEnd){
      isScrollEnd();
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <StyledCustomSelectWrapper>
        <FormControl fullWidth variant={variant}>
          <InputLabel id="select-label">
            {label}
          </InputLabel>
          <Select
            labelId="select-label"
            value={value}
            label={t(label)}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                sx: {
                   maxHeight: "300px",
                   maxWidth: "420px"
                },
                onScroll: loadMoreItems
              },
            }}
          >
            {children}
          </Select>
        </FormControl>
      </StyledCustomSelectWrapper>
    </Box>
  );
};
