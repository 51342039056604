import { CurrentEditFormType } from "../BookingInfo/types"

export type DetailsOfTravelProps = {
  editForm?: (value: CurrentEditFormType) => void
  traveler: any
}

export enum CruiseTransferStatusEnum {
  PreCruiseIsRequired = "Pre cruise is required",
  PostCruiseIsRequired = "Post cruise is required",
  UnderOwnArrangement = "Under own arrangement",
  KnowLater = "To be advised later"
}