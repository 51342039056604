import { CruiseTransferStatusEnum } from "../../DetailsOfTravel/types"

export const CRUISE_TRAVEL_TRANSFER_PRE = [
  {
    value: 1,
    label: CruiseTransferStatusEnum.PreCruiseIsRequired
  },
  {
    value: 3,
    label: CruiseTransferStatusEnum.KnowLater
  },
  {
    value: 2,
    label: CruiseTransferStatusEnum.UnderOwnArrangement
  }
]

export const CRUISE_TRAVEL_TRANSFER_POST = [
  {
    value: 1,
    label: CruiseTransferStatusEnum.PostCruiseIsRequired
  },
  {
    value: 3,
    label: CruiseTransferStatusEnum.KnowLater
  },
  {
    value: 2,
    label: CruiseTransferStatusEnum.UnderOwnArrangement
  }
]