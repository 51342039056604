import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { StyledButtonLogin, StyledError, StyledFormAuthWrapper } from "./FormAuth.styles";
import { FormAuthProps } from "./types";
import { mainTheme } from "../../theme";
import { axiosInstance } from "../../utils/axios";

interface IFormAuthInput {
  bookingCode: string;
  firstName: string;
  lastName: string;
}

export const FormAuth = ({ setIsAuthUser }: FormAuthProps) => {

  const { t } = useTranslation();

  const [errorAuth, setErrorAuth] = useState("")

  const schema = yup.object().shape({
    bookingCode: yup.string().required(t("fieldRequired")),
    firstName: yup.string().required(t("fieldRequired")),
    lastName: yup.string().required(t("fieldRequired")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormAuthInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormAuthInput) => {
    const getUser = 
      await axiosInstance.post(`/security/authorise-as-traveler`, {
        order: data.bookingCode,
        first_name: data.firstName,
        last_name: data.lastName
      }) 
      .then(function (response) {
        setErrorAuth("");
        if (response.data.result.accessToken.token) {
          localStorage.setItem('traveler_id', response.data?.traveler_id);
          localStorage.setItem('tokenAuth', response.data.result.accessToken.token);
          localStorage.setItem('tokenAuthRefetch', response.data.result.refreshToken.token);
          localStorage.setItem('orderId', response.data.order_id);
        }
        setIsAuthUser(true);
        return response;
      })
      .catch(error => {
        setIsAuthUser(false);
        setErrorAuth(error?.response?.data?.message)
      });
    console.log("getUser: ", getUser);
  };

  return (
    <StyledFormAuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="bookingCode"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("bookingCode")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal
                }
              }}
            />
          )}
        />
        {errors.bookingCode && <StyledError>{errors.bookingCode.message}</StyledError>}

        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("firstName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                }
              }}
            />
          )}
        />
        {errors.firstName && <StyledError>{errors.firstName.message}</StyledError>}

        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("lastName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                }
              }}
            />
          )}
        />
        {errors.lastName && <StyledError>{errors.lastName.message}</StyledError>}

        {errorAuth && (
          <StyledError>{errorAuth}</StyledError>
        )}

        <StyledButtonLogin type="submit">
          {t("login")}
        </StyledButtonLogin>
      </form>
    </StyledFormAuthWrapper>
  );
};
