/* eslint-disable */
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import { StyledWrapper } from "./PhoneMaskField.styles";

type PhoneMaskFieldProps = {
  value: any;
  onChange: (value: string) => void;
  label: any;
};

export const PhoneMaskField = ({
  value,
  onChange,
  label,
}: PhoneMaskFieldProps) => {

  const  handleOnChange = (value: string, data: {name: string, dialCode: string, countryCode: string, format: string}) => {
    console.log(data.dialCode);
    onChange(value);
  }

  return (
    <StyledWrapper>
      <PhoneInput
        placeholder={""}
        value={value}
        onChange={handleOnChange}
      />
    </StyledWrapper>
  );
};
