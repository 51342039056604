import { useTranslation } from "react-i18next";
import moment from "moment";
import { CruiseTransferStatusEnum, DetailsOfTravelProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import {
  StyledChange,
  StyledDetailsOfTravelList,
  StyledDetailsOfTravelListColumnWrapper,
  StyledDetailsOfTravelListItemColumn,
  StyledDetailsOfTravelListItemColumnTitle,
  StyledDetailsOfTravelListItemContent,
  StyledDetailsOfTravelOrganization,
  StyledDetailsOfTravelOrganizationStatus,
  StyledDetailsOfTravelOrganizationTitle,
  StyledDetailsOfTravelSection,
  StyledDetailsOfTravelSectionTitle,
  StyledDisclaimer,
  StyledDisclaimerText,
} from "./DetailsOfTravel.styles";

export const DetailsOfTravel = ({
  editForm,
  traveler,
}: DetailsOfTravelProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "detailsOfTravel",
        typeEditForm: TypeFormEnum.detailsTravel,
      });
    }
  };

  // PRE
  const preArrivalDate = traveler?.shuttle_request?.pre_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format(
        "DD.MM.YYYY"
      )
    : "-";

  const preArrivalTime = traveler?.shuttle_request?.pre_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format("HH:mm")
    : "-";

  // POST
  const postArrivalDate = traveler?.shuttle_request?.post_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format(
        "DD.MM.YYYY"
      )
    : "-";

  const postArrivalTime = traveler?.shuttle_request?.post_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format("HH:mm")
    : "-";

  const getTransferStatuPre = (status: number) => {
    if (status === 1) {
      return CruiseTransferStatusEnum.PreCruiseIsRequired;
    }

    if (status === 2) {
      return CruiseTransferStatusEnum.UnderOwnArrangement;
    }

    if (status === 3) {
      return CruiseTransferStatusEnum.KnowLater;
    }

    return "-";
  };

  const getTransferStatusPost = (status: number) => {
    if (status === 1) {
      return CruiseTransferStatusEnum.PostCruiseIsRequired;
    }

    if (status === 2) {
      return CruiseTransferStatusEnum.UnderOwnArrangement;
    }

    if (status === 3) {
      return CruiseTransferStatusEnum.KnowLater;
    }

    return "-";
  };

  const isShowPreCruiseIsRequired =
    Number(traveler?.shuttle_request?.pre_cruise?.status) === 1;

  const isShowPostCruiseTravelArrangements =
    Number(traveler?.shuttle_request?.post_cruise?.status) === 1;

  return (
    <StyledDetailsOfTravelSection>
      <StyledDetailsOfTravelSectionTitle>
        {t("detailsOfTravel")}
      </StyledDetailsOfTravelSectionTitle>

      <StyledDisclaimer>
        <StyledDisclaimerText>
          {t("pleaseCompleteFollowing")}
        </StyledDisclaimerText>
      </StyledDisclaimer>

      <StyledDetailsOfTravelOrganization>
        <StyledDetailsOfTravelOrganizationTitle>
          {t("precruiseTravelArrangements")}
        </StyledDetailsOfTravelOrganizationTitle>
        <StyledDetailsOfTravelOrganizationStatus>
          {getTransferStatuPre(traveler?.shuttle_request?.pre_cruise?.status)}
        </StyledDetailsOfTravelOrganizationStatus>
      </StyledDetailsOfTravelOrganization>

      {/* PRE */}
      {isShowPreCruiseIsRequired && (
        <StyledDetailsOfTravelList>
          <StyledDetailsOfTravelListColumnWrapper>
            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("airline")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.pre_cruise?.airline ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("flightNumber")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.pre_cruise?.flight_number ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("arrivalAirport")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.pre_cruise?.airport ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn className="lastColumn">
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("arrivalDate")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {preArrivalDate}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn className="lastColumn">
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("arrivalTime")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {preArrivalTime}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>
          </StyledDetailsOfTravelListColumnWrapper>
        </StyledDetailsOfTravelList>
      )}

      <StyledDetailsOfTravelOrganization>
        <StyledDetailsOfTravelOrganizationTitle>
          {t("postCruiseTravelArrangements")}
        </StyledDetailsOfTravelOrganizationTitle>
        <StyledDetailsOfTravelOrganizationStatus className="post">
          {getTransferStatusPost(
            traveler?.shuttle_request?.post_cruise?.status
          )}
        </StyledDetailsOfTravelOrganizationStatus>
      </StyledDetailsOfTravelOrganization>

      {/* POST */}
      {isShowPostCruiseTravelArrangements && (
        <StyledDetailsOfTravelList>
          <StyledDetailsOfTravelListColumnWrapper>
            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("airline")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.post_cruise?.airline ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("flightNumber")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.post_cruise?.flight_number ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn>
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("departureAirport")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {traveler?.shuttle_request?.post_cruise?.airport ?? "-"}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn className="lastColumn">
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("departureDate")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {postArrivalDate}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>

            <StyledDetailsOfTravelListItemColumn className="lastColumn">
              <StyledDetailsOfTravelListItemColumnTitle>
                {t("departureTime")}
              </StyledDetailsOfTravelListItemColumnTitle>
              <StyledDetailsOfTravelListItemContent>
                {postArrivalTime}
              </StyledDetailsOfTravelListItemContent>
            </StyledDetailsOfTravelListItemColumn>
          </StyledDetailsOfTravelListColumnWrapper>
        </StyledDetailsOfTravelList>
      )}

      <StyledChange onClick={handleChange}>{t("edit")}</StyledChange>
    </StyledDetailsOfTravelSection>
  );
};
