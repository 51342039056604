/* eslint-disable */
import axios from "axios";
import { axiosInstance } from "../utils/axios";
import { DetailsBookingType } from "../utils/globalTypes";

export const useGetOrderInfo = async (orderId: string, setResponse: (value: DetailsBookingType) => void) => {
  await axiosInstance
    .get(`ru/orders/${orderId}/details-by-traveler`)
    .then(function (response) {
      return setResponse(response?.data)
    })
    .catch((error) => {
      console.log("getOrderInfo error: ", error);
    });
};

export const useGetTravelers = async (orderId: string, setResponse: (value: any) => void) => {
  await axiosInstance
    .get(`ru/orders/${orderId}/travelers`)
    .then(function (response) {
      return setResponse(response?.data)
    })
    .catch((error) => {
      console.log("getTravelers error: ", error);
    });
};

export const useGetTraveler = async (orderId: string, setResponse: (value: any) => void, travelerId: string) => {
  await axiosInstance
    .get(`ru/orders/${orderId}/travelers/${travelerId}`)
    .then(function (response) {
      return setResponse(response?.data)
    })
    .catch((error) => {
      console.log("getTraveler error: ", error);
    });
};

export const useEditTraveler = async (valuesForm: any, orderId: string, setResponse: (value: any) => void, travelerId: string) => {
  
  // Показ попапа с ошибкой
  let countError = 20;
  const minScrollHeight = 20;
  const setElemError = (error: string) => {
    window.scrollTo(0, minScrollHeight); // Прокрутка окна вверх, чтобы попап с ошибкой было видно
    const elem = document.createElement('div');
    elem.style.cssText = `position: absolute; top: ${countError += 60}px; right: 20px; min-width: 220px; padding: 12px; z-index:999999; background: white; opacity: 0.9; border-radius: 4px; border: 2px solid red`;
    document.body.appendChild(elem);
    elem.innerHTML = `<div>${error ? error : "Ошибка"}</div>`
    setTimeout(() => {
      elem.remove()
    }, 6000);
  }

  await axiosInstance
    .patch(`ru/orders/${orderId}/travelers/${travelerId}`, valuesForm)
    .then(function (response) {
      if (response?.data) {
        useGetTraveler(orderId ?? "", setResponse, travelerId ?? "");
      }
      return response;
    })
    .catch((error) => {
      console.log("error: ", error); // Почему-то от бекенда ошибки могут приходить в разных объектах и так далее!
      if (error?.response?.data?.error?.errors?.length) {
        error.response.data.error.errors.map((error: {field: string, message: string}) => {
          return setElemError(error?.message)
        })
      }
      if (error?.response?.data?.error?.error_msg) {
        return setElemError(error.response.data?.error.error_msg)
      }
    });
};

// Пока нужно вызывать данный метод так как он возвращает code 
export const useGetCountriesList = async (setResponse: (value: any) => void) => {
  await axios
    .get(`https://api-crs-stage.swanhellenic.com/v4/references/countries`)
    .then(function (response) {
      return setResponse(response?.data?.data)
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

// export const useGetCountriesList = async (setList: any, defaultValue: any, currentPage: number) => {
//   await axiosInstance
//     .get(`ru/suggestions/countries?page=${currentPage}&limit=${1000}`)
//     .then(function (response) {
//       const isDefault = response.data.filter((item: any) => item.value === defaultValue)?.length;
//       if (isDefault) {
//         setList((prev: any) => [
//           ...prev, 
//           ...response.data,
//         ])
//       } else {
//         setList((prev: any) => [
//           ...prev,
//           {
//             value: defaultValue
//           },
//           ...response.data,
//         ])
//       }
//       return response;
//     })
//     .catch((error) => {
//       console.log("error: ", error);
//     });
// };

export const useGetCitiesList = async (setList: any, defaultValue: any, currentPage: number) => {
  await axiosInstance
    .get(`ru/suggestions/cities?page=${currentPage}&limit=${1000}`)
    .then(function (response) {
      const isDefault = response.data.filter((item: any) => item.value === defaultValue)?.length;
      if (isDefault) {
        setList((prev: any) => [
          ...prev, 
          ...response.data,
        ])
      } else {
        setList((prev: any) => [
          ...prev,
          {
            value: defaultValue
          },
          ...response.data,
        ])
      }
      return response;
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const useGetAirlinesList = async (setList: any, defaultValue: any, currentPage: number) => {
  await axiosInstance
    .get(`ru/suggestions/airlines?page=${currentPage}&limit=${1000}`)
    .then(function (response) {
      const isDefault = response.data.filter((item: any) => item.value === defaultValue)?.length;
      if (isDefault) {
        setList((prev: any) => [
          ...prev, 
          ...response.data,
        ])
      } else {
        setList((prev: any) => [
          ...prev,
          {
            value: defaultValue
          },
          ...response.data,
        ])
      }
      return response;
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const useGetAirportsList = async (setList: any, defaultValue: any, currentPage: number) => {
  await axios
    .get(`https://api-crs.swanhellenic.com/ru/suggestions/airports?limit=${0}`) // Пока смотрим тольок на бой (Андрей Drew сказал)
    .then(function (response) {
      const isDefault = response.data.filter((item: any) => item.value === defaultValue)?.length;
      if (isDefault) {
        setList((prev: any) => [
          ...prev, 
          ...response.data,
        ])
      } else {
        setList((prev: any) => [
          ...prev,
          {
            value: defaultValue
          },
          ...response.data,
        ])
      }
      return response;
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const useGetCurrentUser = async (setResponse: (value: any) => void) => {
  await axiosInstance
    .get(`ru/users/current`)
    .then(function (response) {
      setResponse(response?.data);
      return response;
    })
    .catch((error) => {
      console.log("getCurrentUser error: ", error);
    });
};

export const useGetRelationships = async (setResponse: (value: any) => void) => {
  await axiosInstance
    .get(`ru/suggestions/relationships`)
    .then(function (response) {
      setResponse(response?.data);
      return response;
    })
    .catch((error) => {
      console.log("useGetRelationships error: ", error);
    });
};