/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { FormEditPassportDetailsProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCountriesList } from "../../../api/hooks";
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import {
  StyledFormEditPassportDetailsWrapper,
  StyledFormEditPassportDetailsItem,
  StyledFormEditPassportDetailsItemsList,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFormEditPassportDetailsItemsRow,
  StyledFormEditPassportDetailsAnatation,
} from "./FormEditPassportDetails.styles";

interface IFormEditPassportDetailsInput {
  number?: string | null;
  issue_date?: string | null;
  expires_date?: string | null;
  citizenship?: string | null;
  birth_place?: string | null;
  issue_place?: string | null;
  issue_country?: string | null;
}

export const FormEditPassportDetails = ({
  setOpen,
  traveler,
  editTraveler,
}: FormEditPassportDetailsProps) => {
  const { t } = useTranslation();

  const [countriiesList, setCountriiesList] = useState<SuggestionsListType[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);

  const schema = yup.object().shape({
    number: yup.string().nullable(),
    issue_date: yup.string().nullable(),
    expires_date: yup.string().nullable(),
    // citizenship: yup.string().nullable(),
    birth_place: yup.string().nullable(),
    issue_place: yup.string().nullable(),
    // issue_country: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditPassportDetailsInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      document: {
        number: data.number ?? null,
        issue_date: data.issue_date
          ? dayjs(data.issue_date).format("YYYY-MM-DD")
          : null,
        expires_date: data.expires_date
          ? dayjs(data.expires_date).format("YYYY-MM-DD")
          : null,
        citizenship: data?.citizenship?.code,
        issue_place: data.issue_place ?? null,
        birth_place: data.birth_place ?? null, // Такого поля нет на бекенде
        issue_country: data?.issue_country?.code, // Такого поля нет на бекенде
      },
    };

    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditPassportDetails formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Так как бекенд возвращает code (потому-что при отправке формы беку нужен code)
  // const getSelectedCitizenship = countriiesList?.length && countriiesList?.filter((item) => item.code === traveler?.document?.citizenship)[0];

  // Так как бекенд возвращает code (потому-что при отправке формы беку нужен code)
  // const getSelectedIssueCountry = countriiesList?.length && countriiesList?.filter((item) => item.code === traveler?.document?.issue_country)[0];

  useEffect(() => {
    if (traveler) {
      reset({
        number: traveler?.document?.number ?? null,
        issue_date: traveler?.document?.issue_date ?? null,
        expires_date: traveler?.document?.expires_date ?? null,
        citizenship: traveler?.document?.citizenship?.value ?? "", // Переименовал старое имя placeOfBirth
        birth_place: traveler?.document?.birth_place,
        issue_place: traveler?.document?.issue_place ?? null,
        issue_country: traveler?.document?.issue_country?.value ?? "",
      });
    }
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  // useEffect(() => {
  //   useGetCountriesList(
  //     setCountriiesList,
  //     traveler?.document?.citizenship,
  //     currentPage
  //   );
  // }, [currentPage]);

  useEffect(() => {
    useGetCountriesList(setCountriiesList);
  }, []);

  return (
    <StyledFormEditPassportDetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditPassportDetailsItemsRow>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("number")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issue_date"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label="issueDate"
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="expires_date"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <FieldDatePicker
                    onChange={onChange}
                    value={value ? dayjs(value) : null}
                    label="expirationDate"
                  />
                );
              }}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsRow>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="citizenship"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div>
                {!!countriiesList?.length && (
                  <CustomAutocomplete
                    label={t("nationality")}
                    value={value}
                    onChange={onChange}
                    items={countriiesList}
                    getOptionLabel={(option: {
                      code: string;
                      value: string;
                    }) => {
                      if (option?.hasOwnProperty("value")) {
                        return option.value;
                      }
                      return option;
                    }}
                    minSize={200}
                    variant="standard"
                    keyParams="value"
                  />
                )}
              </div>
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="birth_place"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("placeOfBirth")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItemsList>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issue_place"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfIssue")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issue_country"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div>
                  {!!countriiesList?.length && (
                    <CustomAutocomplete
                      label={t("issueCountry")}
                      value={value}
                      onChange={onChange}
                      items={countriiesList}
                      getOptionLabel={(option: {
                        code: string;
                        value: string;
                      }) => {
                        if (option?.hasOwnProperty("value")) {
                          return option.value;
                        }
                        return option;
                      }}
                      minSize={200}
                      variant="standard"
                      keyParams="value"
                    />
                  )}
                </div>
              )}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsList>

        <StyledFormEditPassportDetailsAnatation>
          {t("anatationEditPassportDetails")}
        </StyledFormEditPassportDetailsAnatation>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPassportDetailsWrapper>
  );
};
