import { Button, MenuItem } from "@mui/material";
import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledLoginWrapper = styled.div``

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegularMedium};
  }
`

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background: ${mainTheme.main.colors.blue};
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.small};
    font-weight: ${mainTheme.main.fontWeights.bold};
    font-family: ${mainTheme.main.fonts.dmsRegularMedium};
    border-radius: 0;
    text-transform: capitalize;
    &:hover {
      background: ${mainTheme.main.colors.blue};
      color: ${mainTheme.main.colors.white};
    }
  }
`

export const StyledInitials = styled.div`
  text-transform: capitalize;
`