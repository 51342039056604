/* eslint-disable */
import { useEffect, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, MenuItem, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import {
  CRUISE_TRAVEL_TRANSFER_POST,
  CRUISE_TRAVEL_TRANSFER_PRE,
} from "./consts";
import { FormEditDetailsOfTravelProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetAirlinesList, useGetAirportsList } from "../../../api/hooks";
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import {
  StyledFormEditDetailsOfTravelWrapper,
  StyledFormEditDetailsOfTravelItem,
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormEditDetailsOfTravelItemTwoColumn,
  StyledFormEditDetailsOfTravelItemThreeColumn,
} from "./FormEditDetailsOfTravel.styles";

interface IFormEditDetailsOfTravelInput {
  precruiseIsRequired?: string;
  airline?: {
    code: string;
    value: string;
  };
  flightNumber?: string;
  airport?: {
    code: string;
    value: string;
  };
  arrivalDate?: string | any;
  arrivalTime?: string;
  postCruiseTravelArrangements?: string;
  airlineDeparture?: {
    code: string;
    value: string;
  };
  flightNumberDeparture?: string;
  airportDeparture?: {
    code: string;
    value: string;
  };
  departureDate?: string | any;
  departureTime?: string;
}

export const FormEditDetailsOfTravel = ({
  setOpen,
  traveler,
  editTraveler,
  statusId,
}: FormEditDetailsOfTravelProps) => {
  const { t } = useTranslation();

  const [airlinesPreList, setAirlinesPreList] = useState<SuggestionsListType[]>(
    []
  );
  const [airlinesPostList, setAirlinesPostList] = useState<
    SuggestionsListType[]
  >([]);

  const [airportsPreList, setAirportsPreList] = useState<SuggestionsListType[]>(
    []
  );
  const [airportsPostList, setAirportsPostList] = useState<
    SuggestionsListType[]
  >([]);

  const [currentPageAirline, setCurrentPageArline] = useState(1);
  const [currentPageAirport, setCurrentPageAirport] = useState(1);

  const schema = yup.object().shape({
    precruiseIsRequired: yup.string(),
    // airline: yup.object().shape({
    //   code: yup.string(),
    //   value: yup.string(),
    // }),
    flightNumber: yup.string(),
    // airport: yup.object().shape({
    //   code: yup.string(),
    //   value: yup.string(),
    // }),
    arrivalDate: yup.string(),
    arrivalTime: yup.string(),
    postCruiseTravelArrangements: yup.string(),
    // airlineDeparture: yup.object().shape({
    //   code: yup.string(),
    //   value: yup.string(),
    // }),
    flightNumberDeparture: yup.string(),
    // airportDeparture: yup.object().shape({
    //   code: yup.string(),
    //   value: yup.string(),
    // }) || yup.string(),
    departureDate: yup.string(),
    departureTime: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const getWatch = watch();

  const preArrivalDate = moment(
    traveler?.shuttle_request?.pre_cruise?.shuttle_at
  ).format("MM.DD.YYYY");

  const preArrivalTime = traveler?.shuttle_request?.pre_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format("HH:mm")
    : "00:00";

  const postArrivalDate = moment(
    traveler?.shuttle_request?.post_cruise?.shuttle_at
  ).format("MM.DD.YYYY");

  const postArrivalTime = traveler?.shuttle_request?.post_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format("HH:mm")
    : "00:00";

  const onSubmit = async (data: IFormEditDetailsOfTravelInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      status: statusId,
      shuttle_request: {
        pre_cruise: {
          status: Number(data.precruiseIsRequired),
          airline: data.airline?.value ? data.airline?.value : data?.airline, // code всегда пусто из справочника (пока)
          flight_number: data.flightNumber,
          airport: data.airport?.value ? data.airport?.value : data?.airport, // code всегда пусто из справочника (пока)
          shuttle_at:
            data.arrivalDate === "Invalid date"
              ? data.arrivalTime
              : `${moment(data.arrivalDate).format("YYYY-MM-DD")} ${
                  data.arrivalTime
                }`,
        },
        post_cruise: {
          status: Number(data.postCruiseTravelArrangements),
          airline: data.airlineDeparture?.value ? data.airlineDeparture?.value : data?.airlineDeparture, // code всегда пусто из справочника (пока)
          flight_number: data.flightNumberDeparture,
          airport: data.airportDeparture?.value ? data.airportDeparture?.value : data?.airportDeparture, // code всегда пусто из справочника (пока)
          shuttle_at:
            data.departureDate === "Invalid date"
              ? data.departureTime
              : `${moment(data.departureDate).format("YYYY-MM-DD")} ${
                  data.departureTime
                }`,
        },
      },
    };
    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditDetailsOfTravel formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    useGetAirlinesList(
      setAirlinesPreList,
      traveler?.shuttle_request?.pre_cruise?.airline,
      currentPageAirline
    );
    useGetAirlinesList(
      setAirlinesPostList,
      traveler?.shuttle_request?.post_cruise?.airline,
      currentPageAirline
    );
  }, [currentPageAirline]);

  useEffect(() => {
    useGetAirportsList(
      setAirportsPreList,
      traveler?.shuttle_request?.pre_cruise?.airport,
      currentPageAirport
    );
    useGetAirportsList(
      setAirportsPostList,
      traveler?.shuttle_request?.post_cruise?.airport,
      currentPageAirport
    );
  }, [currentPageAirport]);

  useEffect(() => {
    reset({
      // PRE
      precruiseIsRequired: traveler?.shuttle_request?.pre_cruise?.status ?? "",
      airline: traveler?.shuttle_request?.pre_cruise?.airline ?? "",
      flightNumber: traveler?.shuttle_request?.pre_cruise?.flight_number ?? "",
      airport: traveler?.shuttle_request?.pre_cruise?.airport ?? "",
      arrivalDate: preArrivalDate ?? "",
      arrivalTime: preArrivalTime ?? "",
      // POST
      postCruiseTravelArrangements:
        traveler?.shuttle_request?.post_cruise?.status ?? "",
      airlineDeparture: traveler?.shuttle_request?.post_cruise?.airline ?? "",
      flightNumberDeparture:
        traveler?.shuttle_request?.post_cruise?.flight_number ?? "",
      airportDeparture: traveler?.shuttle_request?.post_cruise?.airport ?? "",
      departureDate: postArrivalDate ?? "",
      departureTime: postArrivalTime ?? "",
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEndAirline = () => {
    setCurrentPageArline(currentPageAirline + 1);
  };

  // Lazy load для списков справочников
  const handleIsScrollEndAirports = () => {
    setCurrentPageAirport(currentPageAirport + 1);
  };

  const isShowPreCruiseIsRequired = Number(getWatch?.precruiseIsRequired) === 1;

  const isShowPostCruiseTravelArrangements =
    Number(getWatch?.postCruiseTravelArrangements) === 1;

  return (
    <StyledFormEditDetailsOfTravelWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditDetailsOfTravelItem>
          <Controller
            control={control}
            name="precruiseIsRequired"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("precruiseTravelArrangements")}
                onChange={onChange}
                defaultValue={traveler?.shuttle_request?.pre_cruise?.status}
              >
                {CRUISE_TRAVEL_TRANSFER_PRE.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFormEditDetailsOfTravelItem>

        {isShowPreCruiseIsRequired && (
          <>
            <StyledFormEditDetailsOfTravelItemTwoColumn>
              <StyledFormEditDetailsOfTravelItem className="selectField">
                <Controller
                  control={control}
                  name="airline"
                  render={({ field: { onChange, onBlur, value, ref } }) =>
                    airlinesPreList && (
                      <CustomAutocomplete
                        label={t("airline")}
                        value={value}
                        onChange={onChange}
                        items={airlinesPreList}
                        getOptionLabel={(option: {code: string, value: string}) => {
                          if (option?.hasOwnProperty('value')) {
                            return option.value;
                          }
                          return option;
                        }}
                        minSize={200}
                        variant="standard"
                        keyParams="value"
                      />
                    )
                  }
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="flightNumber"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <TextField
                        label={t("flightNumber")}
                        variant="standard"
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          className: "textFieldForm",
                          style: {
                            color: mainTheme.main.colors.black,
                            fontSize: mainTheme.main.fontSizes.large,
                            fontWeight: mainTheme.main.fontWeights.normal,
                            fontFamily: mainTheme.main.fonts.dmsRegular,
                          },
                        }}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemTwoColumn>

            <StyledFormEditDetailsOfTravelItemThreeColumn>
              <StyledFormEditDetailsOfTravelItem className="selectFieldThreeColumns">
                <Controller
                  control={control}
                  name="airport"
                  render={({ field: { onChange, value } }) => {
                    return (
                      airportsPreList && (
                        <CustomAutocomplete
                          label={t("airport")}
                          value={value}
                          onChange={onChange}
                          items={airportsPreList}
                          getOptionLabel={(option: {code: string, value: string}) => {
                            if (option?.hasOwnProperty('value')) {
                              return option.value;
                            }
                            return option;
                          }}
                          minSize={200}
                          variant="standard"
                          keyParams="value"
                        />
                      )
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="arrivalDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FieldDatePicker
                      onChange={onChange}
                      value={value}
                      label="arrivalDate"
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="arrivalTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label={t("arrivalTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      type="time"
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemThreeColumn>
          </>
        )}

        <StyledFormEditDetailsOfTravelItem>
          <Controller
            control={control}
            name="postCruiseTravelArrangements"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("postCruiseTravelArrangements")}
                onChange={onChange}
                defaultValue={traveler?.shuttle_request?.post_cruise?.status}
              >
                {CRUISE_TRAVEL_TRANSFER_POST.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFormEditDetailsOfTravelItem>

        {/* Departure */}
        {isShowPostCruiseTravelArrangements && (
          <>
            <StyledFormEditDetailsOfTravelItemTwoColumn>
              <StyledFormEditDetailsOfTravelItem className="selectField">
                <Controller
                  control={control}
                  name="airlineDeparture"
                  render={({ field: { onChange, onBlur, value, ref } }) =>
                    airlinesPostList && (
                      <CustomAutocomplete
                        label={t("airline")}
                        value={value}
                        onChange={onChange}
                        items={airlinesPostList}
                        getOptionLabel={(option: {code: string, value: string}) => {
                          if (option?.hasOwnProperty('value')) {
                            return option.value;
                          }
                          return option;
                        }}
                        minSize={200}
                        variant="standard"
                        keyParams="value"
                      />
                    )
                  }
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="flightNumberDeparture"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <TextField
                        label={t("flightNumber")}
                        variant="standard"
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          className: "textFieldForm",
                          style: {
                            color: mainTheme.main.colors.black,
                            fontSize: mainTheme.main.fontSizes.large,
                            fontWeight: mainTheme.main.fontWeights.normal,
                            fontFamily: mainTheme.main.fonts.dmsRegular,
                          },
                        }}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemTwoColumn>

            <StyledFormEditDetailsOfTravelItemThreeColumn>
              <StyledFormEditDetailsOfTravelItem className="selectFieldThreeColumns">
                <Controller
                  control={control}
                  name="airportDeparture"
                  render={({ field: { onChange, value } }) =>
                    airportsPostList && (
                      <CustomAutocomplete
                        label={t("airport")}
                        value={value}
                        onChange={onChange}
                        items={airportsPostList}
                        getOptionLabel={(option: {code: string, value: string}) => {
                          if (option?.hasOwnProperty('value')) {
                            return option.value;
                          }
                          return option;
                        }}
                        minSize={200}
                        variant="standard"
                        keyParams="value"
                      />
                    )
                  }
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="departureDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <FieldDatePicker
                        onChange={onChange}
                        value={value}
                        label={t("departureDate")}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="departureTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label={t("departureTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      type="time"
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemThreeColumn>
          </>
        )}

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditDetailsOfTravelWrapper>
  );
};
