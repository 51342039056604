import styled from 'styled-components';
import { mainTheme } from '../../../../theme';

export const StyledWrapper = styled.div`
  .react-tel-input {
    .special-label {
      position: static;
      padding-left: 0;
      font-size: 14px;
      font-weight: ${mainTheme.main.fontWeights.normal};
      color: ${mainTheme.main.colors.black};
      font-family: ${mainTheme.main.fonts.dmsRegular};
      opacity: 0.5;
    }
    .form-control {
      border: none;
      border-bottom: 1px solid ${mainTheme.main.colors.black};
      border-radius: 0;
      padding-top: 0px;
      padding-bottom: 4px;
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.normal};
      color: ${mainTheme.main.colors.black};
      font-family: ${mainTheme.main.fonts.dmsRegular};
      padding-left: 40px;
      width: 100%;
      margin-top: 6px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        border-bottom: 2px solid ${mainTheme.main.colors.black};
      }
    }

    .flag-dropdown {
      top: 20px;
      left: -12px;
    }

    .country-list {
      width: 320px;
      max-height: 170px;
    }
  }
` 