/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { FormEditEmergencyProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCountriesList, useGetRelationships } from "../../../api/hooks";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomSelect } from "../components/CustomSelect";
import { PhoneMaskField } from "../components/PhoneMaskField";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormEditEmergencyWrapper,
} from "./FormEditEmergency.styles";

interface IFormEditEmergencyInput {
  first_name?: string;
  last_name?: string;
  relationship?: string;
  phone?: string;
  email?: string;
}

export const FormEditEmergency = ({
  setOpen,
  traveler,
  editTraveler,
}: FormEditEmergencyProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<SuggestionsListType[]>([]);
  const [relationshipList, setRelationshipList] = useState<
    SuggestionsListType[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);

  const schema = yup.object().shape({
    first_name: yup.string(),
    last_name: yup.string(),
    relationship: yup.string(),
    phone: yup.string(),
    email: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditEmergencyInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditEmergencyInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      emergency_contact: {
        first_name: data.first_name,
        last_name: data.last_name,
        relationship: data.relationship,
        phone: data.phone,
        email: data.email,
      },
    };

    console.log("FormEditEmergency formValues: ", formValues);

    editTraveler(formValues);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset({
      first_name: traveler?.emergency_contact?.first_name ?? "",
      last_name: traveler?.emergency_contact?.last_name ?? "",
      relationship: traveler?.emergency_contact?.relationship ?? "",
      phone: traveler?.emergency_contact?.phone ?? "",
      email: traveler?.emergency_contact?.email ?? "",
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  // useEffect(() => {
  //   useGetCountriesList(setCountriesList, traveler?.personal_info?.nationality, currentPage);
  // }, [currentPage]);

  useEffect(() => {
    useGetCountriesList(setCountriesList);
    useGetRelationships(setRelationshipList);
  }, []);

  return (
    <StyledFormEditEmergencyWrapper>
      <form>
        <Controller
          control={control}
          name="first_name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("firstName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="last_name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("lastName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="relationship"
          render={({ field: { onChange, value } }) => (
            <div>
              {!!relationshipList?.length && (
                <CustomSelect
                  label={t("relationship")}
                  onChange={onChange}
                  defaultValue={value}
                  variant={VariantCustomSelectEnum.standard}
                >
                  {relationshipList?.length &&
                    relationshipList?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                </CustomSelect>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, value } }) => (
            <PhoneMaskField
              value={value}
              label={t("phone")}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("email")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditEmergencyWrapper>
  );
};
