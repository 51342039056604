import styled from "styled-components";
import { mainTheme } from "../../theme";

// Раздел "Personal Information"
export const StyledBookingInfoPersonalInfoSection = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto) minmax(410px, 482px);
  gap: 10px;
  grid-auto-flow: row;
  max-width: 1280px;
  margin: auto;
  margin-top: 34px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px;
    margin-top: 0;
    grid-template-columns: minmax(100%, auto);
  }
`

export const StyledBookingInfoPersonalInfoWrapper = styled.div``

export const StyledBookingInfoItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px 16px 24px 16px;
  }
`;

export const StyledBookingInfoItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  text-transform: uppercase;
  font-family: ${mainTheme.main.fonts.chapMedium};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`;

export const StyledBookingInfoPersonalInfoContent = styled.div`
  margin-top: 15px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 0px;
  }
`

export const StyledBookingInfoPersonalInfoContentItem = styled.div``

export const StyledBookingInfoPersonalInfoContentSubItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
`

export const StyledDocumentIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`

export const StyledDocumentName = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;
`

export const StyledBookingInfoPersonalInfoContentSubItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegularMedium};
`

export const StyledBookingInfoPersonalInfoListItemColumn = styled.div`
  display: flex;
  flex-direction: column;

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }
`

export const StyledBookingInfoPersonalInfoListItem = styled.div`
  margin-bottom: 20px;
`

export const StyledBookingInfoPersonalInfoListItemTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledBookingInfoPersonal = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
  text-transform: capitalize;
  min-height: 22px;
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

// Блок "Карта Лояльности" и Блок "Документы"
export const StyledBookingInfoPersonalInfoDock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  grid-auto-flow: column;


  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`


export const StyledBookingInfoPersonalInfoList = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(100px, 160px) minmax(100px, 135px) minmax(115px, auto);
  grid-auto-flow: row;
  gap: 20px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: minmax(100px, auto) minmax(100px, auto);
    gap: 10px;
    grid-auto-flow: column;
  }
`