export const IconShip = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={14}
    fill="none"
  >
    <path
      fill="none"
      d="M20.188 14H17.15a2.566 2.566 0 0 1-1.245-.33 2.524 2.524 0 0 1-.92-.89L7.631.306s2.033-.192 3.411 2.157l5.381 9.134c.308.522.517.673 1.152.673h1.507l-4.53-7.698C13.56 2.88 14.696.928 15.926.346c1.279-.608 3.35-.578 4.466 1.445.527.955.05 2.25.05 2.25l.42 1.19s.12.394-.112.463c-.233.07-.4-.216-.4-.216L18.57 2.42a1.37 1.37 0 0 0-.819-.58 1.387 1.387 0 0 0-1 .13c-.54.311-.98 1.016-.529 1.79l4.843 8.244c.63 1.048.432 1.997-.877 1.997M4.642 14H2.49L.41 10.47s2.066-.13 3.408 2.157L4.642 14ZM13.431 14H8.337c-.439 0-.87-.113-1.25-.327a2.516 2.516 0 0 1-.925-.893L2.81 7.09s2.034-.192 3.411 2.155l1.39 2.352c.308.521.517.672 1.152.672h1.506L5.22 3.711s2.034-.193 3.412 2.154L13.43 14Z"
    />
  </svg>
)
