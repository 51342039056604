export enum AddiatinalServisexEnum {
  preCruise = "Pre-cruise",
  onBoard = "On board",
  excursions = "Excursions",
  postCruise = "Post-cruise"
}

export enum StatusEnum {
  success = "Success",
  inCruise = "In Cruise",
  canceled = "Canceled",
  booked = "Booked",
  hold = "Hold",  
}