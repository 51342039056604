/* eslint-disable */
import { t } from "i18next";
import { PassportDetailsProps } from "./types";
import moment from "moment";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { EmailSubscriptions } from "../EmailSubscriptions";
import {
  StyledChange,
  StyledPassportDetailsList,
  StyledPassportDetailsListColumnWrapper,
  StyledPassportDetailsListItemColumn,
  StyledPassportDetailsListItemColumnTitle,
  StyledPassportDetailsListItemContent,
  StyledPassportDetailsSection,
  StyledPassportDetailsSectionItem,
  StyledPassportDetailsSectionItemTitle,
} from "./PassportDetails.styles";

export const PassportDetails = ({
  editForm,
  traveler,
}: PassportDetailsProps) => {
  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "passportDetails",
        typeEditForm: TypeFormEnum.passportDetails,
      });
    }
  };

  return (
    <StyledPassportDetailsSection>
      <StyledPassportDetailsSectionItem>
        <StyledPassportDetailsSectionItemTitle>
          {t("passportDetails")}
        </StyledPassportDetailsSectionItemTitle>

        <StyledPassportDetailsList>
          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("number")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.number ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfBirth")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.birth_place ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("issueDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.issue_date ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("expirationDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.expires_date
                  ? moment(
                      traveler?.document?.expires_date,
                      "YYYY-MM-DD"
                    ).format("DD MMM YYYY")
                  : "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfIssue")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.issue_place ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("nationality")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {/* Переименовал в Nationality */}
                {traveler?.document?.citizenship?.name ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("issueCountry")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.issue_country?.name ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>
        </StyledPassportDetailsList>

        <StyledChange onClick={handleChange}>{t("edit")}</StyledChange>
      </StyledPassportDetailsSectionItem>

      {/* <StyledPassportDetailsSectionItem className="emailSubscriptions">
        <EmailSubscriptions />
      </StyledPassportDetailsSectionItem> */}
    </StyledPassportDetailsSection>
  );
};
