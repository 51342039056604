import { useEffect, useState } from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { StyledWrapper } from "./CustomAutocomplete.styles";

type CustomAutocompleteProps = {
  value: any;
  onChange: (value: any) => void;
  items: any;
  getOptionLabel: any;
  label: any;
  minSize: number
  variant: TextFieldVariants,
  keyParams: string
};

export const CustomAutocomplete = ({
  value,
  onChange,
  items,
  getOptionLabel,
  label,
  minSize,
  variant,
  keyParams // ключ в объекте по которому получаем значение
}: CustomAutocompleteProps) => {

  const [inputValue, setInputValue] = useState(value ?? "")

  const OPTIONS_LIMIT = 100000;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });

  // Для default значения
  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value]);

  return (
    <StyledWrapper>
      <Autocomplete
        filterOptions={filterOptions}
        value={inputValue}
        onChange={(event, newValue: any) => {
          onChange(newValue);
          setInputValue(newValue?.[keyParams] ?? "");
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        inputValue={inputValue}
        options={items}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} label={label ?? ""}  variant={variant ?? "standard"} />}
        sx={{ minWidth: minSize }}
      />
    </StyledWrapper>
  );
};
