import styled from "styled-components";
import { mainTheme } from "../../theme";

// Раздел "Current booking"
export const StyledBookingInfoWrapper = styled.div`
  border: 2px solid ${mainTheme.main.colors.merin};
  padding: 16px 24px 20px 24px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
  }
`;

export const StyledBookingInfoItem = styled.div`
  background: ${mainTheme.main.colors.white};
`;

export const StyledBookingInfoItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  text-transform: uppercase;
  font-family: ${mainTheme.main.fonts.chapMedium};

  span {
    &.openModalBookingContact {
      color: ${mainTheme.main.colors.blue};
      font-size: ${mainTheme.main.fontSizes.medium};
      font-weight: ${mainTheme.main.fontWeights.normal};
      text-transform: capitalize;
      font-family: ${mainTheme.main.fonts.dmsRegular};
      text-decoration: underline;
      padding-left: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const StyledBookingInfoItemColumnsWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;
  border-bottom: 1px solid ${mainTheme.main.colors.grayLigth};
  padding-bottom: 16px;
  margin-bottom: 16px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledBookingInfoFormWrapper = styled.div``;

export const StyledRouteInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
`;

export const StyledBorderRoute = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 0px;
  border-left: 1px solid ${mainTheme.main.colors.graySilver};
  box-sizing: border-box;
  
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    flex-direction: row;
    margin-top: 0px;

    border-left: none;
    border-top: 1px solid ${mainTheme.main.colors.yellow};
  }
`;

export const StyledIconRouteWrapper = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
    position: absolute;
    top: -10px;
    z-index: 22;
  }

  &.startRoute {
    left: 0;
  }

  &.endRoute {
    right: 0;
  }
`;

export const StyledRouteInformationItem = styled.div`
  margin-top: -15px;

  p {
    line-height: 18px !important;
  }

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    &:last-child {
      align-items: flex-end;
    }
  }

  &.desktop {
    display: block;
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      justify-content: flex-end;
      p {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }
  }

  &:last-child {
    margin-top: 0px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &.desktop {
      display: block;
      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: flex;
      }
    }
  }
`;

export const StyledRouteName = styled.div`
  position: relative;
  background: ${mainTheme.main.colors.white};
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegularMedium};
  padding-left: 6px;
  
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding-left: 0;
  }

  &::before {
    position: absolute;
    left: -5px;
    top: 5px;
    content: "";
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};
    border: 2px solid ${mainTheme.main.colors.black};
    -webkit-box-sizing: content-box !important;
  
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &.portNameEnd {
    margin-top: 20px;
    line-height: 10px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      margin-top: 0;
      line-height: 20px
    }

    &::before {
      content: "";
      display: block;
      top: auto;
      bottom: 0px;
      -webkit-box-sizing: content-box !important;

      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: none;
      }
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const StyledRouteDate = styled.div`
  p {
    margin: 0;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family:  ${mainTheme.main.fonts.dmsRegular};
    padding: 0px 0px 0px 6px;
  
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &:last-child {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const StyledLinkRoute = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: 16px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};
  padding-left: 6px;
  margin-top: 0px;
  
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
  }
`;

// Раздел "Additional services"
export const StyledAdditionalServiceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;
  margin-top: 8px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    gap: 0px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

`

export const StyledAdditionalServiceItemColumns = styled.div`
  width: 100%;
`

export const StyledAdditionalServiceItemColumn = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  min-width: 180px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    min-width: auto;
  }
  
  &.name {}

  &.last {
    text-align: right;
  }

  &.amount {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
`
export const StyledAdditionalServiceTypeName = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
  margin-top: 0px;
  margin-bottom: 14px;
`

export const StyledSectionDateStartAdventure = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
  margin-top: 12px;
`

export const StyledDateStartAdventure = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledDatesService = styled.div`
  position: relative;
  color: ${mainTheme.main.colors.grayOpacity};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 4px;
  padding-left: 10px;

  &::before {
    content: "";
    display: block;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    background: ${mainTheme.main.colors.merin};
    position: absolute;
    top: 8px;
    left: 0px;
    -webkit-box-sizing: content-box !important;
  }
`

export const StyledNameSevice = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: flex-end;
  gap: 8px;
`

export const StyledNameCategoryService = styled.div`
  color: ${mainTheme.main.colors.grayOpacity};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegularMedium};
  font-size: ${mainTheme.main.fontSizes.medium};
  padding-left: 12px;
`

export const StyledModalBookingContactContent = styled.div`
  margin-top: 20px;
`

export const StyledModalBookingContactColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, auto) minmax(100px, auto);
  grid-auto-flow: row;
  gap: 20px;
`

export const StyledModalBookingContactColumn = styled.div``

export const StyledModalBookingContactColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledModalBookingContactColumnData = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
  text-transform: none;
`

export const StyledDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 16px;
`

export const StyledDisclaimerTitle = styled.div`
  color: ${mainTheme.main.colors.yellow};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
  font-size: ${mainTheme.main.fontSizes.large};

  &.alert {
    color: ${mainTheme.main.colors.red};
  }
`

export const StyledDisclaimerText = styled.div`
  color: ${mainTheme.main.colors.yellow};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};
  max-width: 825px;

  &.alert {
    color: ${mainTheme.main.colors.red};
  }
`

export const StyledBookingItemsList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(100px, auto) minmax(100px, 320px) minmax(100px, 220px) minmax(auto, auto);
  grid-auto-flow: row;
  gap: 20px;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   gap: 0px;
  }
`

export const StyledFormCurrnetBookingItemWrapper = styled.div`
  &.lastColumnInMobile {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
`

export const StyledFormCurrnetBookingItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;

  &.lastColumnInMobile {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      align-items: flex-end;
    }
  }

`

export const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  svg {
    stroke: none !important;
    path {
      fill: #004155 !important;
    }
  }
`

export const StyledNameColumnCurrentBooking = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 14px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}){
    font-size: 16px;
  }
`

export const StyledContentColumnCurrentBooking = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};

  &.status {
    background: ${mainTheme.main.colors.blueLigth};
    color: ${mainTheme.main.colors.blueFantom};
    font-family:  ${mainTheme.main.fonts.dmsRegularBold};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.boldExtra};
    text-transform: uppercase;
    border-radius: 4px;
    padding: 3px 12px;
    max-width: fit-content;
  }
`