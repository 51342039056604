import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldDatePickerProps } from './types';
import 'dayjs/locale/ru';
import { StyledDatePickerWrapper } from './FieldDatePicker.styles';

export const FieldDatePicker = ({ label, onChange, value, disabled }: FieldDatePickerProps) => {
  const [locale, setLocale] = useState('en');

  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <StyledDatePickerWrapper>
        <DatePicker 
          label={t(label)}
          onChange={onChange}
          onAccept={onChange}
          value={dayjs(value)}
          format="DD.MM.YYYY"
          slotProps={{ 
            textField: { variant: "standard" },
            popper: {
              sx: {
                ".MuiPickersDay-root": { 
                  fontSize: 14
                 },
                 ".MuiTypography-caption": {
                  fontSize: 12
                 },
                 ".MuiPickersCalendarHeader-label": {
                  fontSize: 14
                 },
                 ".MuiIconButton-root": {
                  "svg": {
                    width: "20px",
                    height: "20px"
                  }
                 }
              },
            },
          }}
          disabled={disabled}
        />
      </StyledDatePickerWrapper>
    </LocalizationProvider>
  );
} 